
import { computed, defineComponent, onMounted, reactive, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { LIVE_STATUS } from '@/api/live/live.type'
import LiveStatusBadge from '@/components/live-stream/LiveStatusBadge.vue'
import { convertToDate, convertToTime, formatDefault } from '@/utils/dayjs'
import { View } from '@element-plus/icons'
import { deleteLive } from '@/api/live/live.api'
import { ElMessageBox } from 'element-plus'
import { useLiveStore } from '@/pinia/live/live.pinia'
import { useFacebookPageStore } from '@/pinia/facebook-page/facebook-page.pinia'
import { useWindowSize } from '@/use/useWindowSize'

export default defineComponent({
	name: 'liveStreamPageIndex',
	components: {
		LiveStatusBadge,
		View,
	},
	setup () {
		const route = useRoute()
		const router = useRouter()
		const liveStore = useLiveStore()
		const facebookPageStore = useFacebookPageStore()
		const { mobileMode: isMobile } = useWindowSize()
		const isReactNativeWebview = computed(() => window.ReactNativeWebView)

		const liveData = reactive({
			isFetching: false,
			search: '',
			offset: 1,
			limit: 10,
			liveList: computed(() => liveStore.liveList)
		})
		const currentPage = computed(() => facebookPageStore.findPageByPageId(route.params.pageId as string))
		const activeTab = ref<LIVE_STATUS|null|string>('ALL')
		const onChangeTab = (_: any) => {
			router.push({ query: { ...route.query, status: activeTab.value === 'ALL' ? undefined : activeTab.value } })
			fetchLiveList()
		}

		const fetchLiveList = async () => {
			liveData.isFetching = true
			await liveStore.getAllPageLiveList({
				pageId: currentPage?.value?._id as string,
				name: liveData.search,
				limit: liveData.limit,
				offset: liveData.offset,
				// offset: ((liveData.offset - 1) * liveData.limit) + 1,
				...activeTab.value !== 'ALL' && { status: activeTab.value }
			})
			
			liveData.isFetching = false
		}

		const deleteThisLive = async (row: any) => {
			try {
				//
				const isConfirm = await ElMessageBox.confirm(
					'คุณต้องการลบการถ่ายทอดสด ?',
					{
						confirmButtonText: 'ยืนยัน',
						cancelButtonText: 'ยกเลิก',
						type: 'warning',
						showClose: !isReactNativeWebview.value
					})
				if (!isConfirm) return

				await deleteLive(row._id)
				await fetchLiveList()
			} catch (error) {
				console.error(error)
			}
		}

		onMounted(() => {
			if (route.query.status) {
				activeTab.value = route.query.status as LIVE_STATUS
			}
			if (route.query.page) {
				liveData.offset = +route.query.page
			}
			fetchLiveList()
		})

		return {
			LIVE_STATUS,
			liveData,
			activeTab,
			currentPage,
			fetchLiveList,
			onChangeTab,
			convertToDate,
			convertToTime,
			formatDefault,
			deleteThisLive,
			isMobile
		}
	}
})
