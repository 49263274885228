import { defineStore } from 'pinia'
import { State, FilterLivePayload } from '@/pinia/live/types'
import { IProductListRes } from '@/api/products/type'
import { getLiveListInPage } from '@/api/live/live.api'

export const useLiveStore = defineStore('live', {
	state: (): State => ({
		liveList: [],
		liveProductsMaster: [],
		liveProducts: []
	}),
	getters: {
		isLiveProductAndLiveProductMasterEqual: (state: State) => {
			const masterLiveProducts = JSON.stringify(state.liveProductsMaster)
			const liveProducts = JSON.stringify(state.liveProducts)

			return liveProducts === masterLiveProducts
		}
	},
	actions: {
		// old mutations
		updatePageLiveList(liveList: []) {
			this.liveList = liveList
		},
		updateLiveProductsMaster(liveProducts: Array<IProductListRes>) {
			this.liveProductsMaster = liveProducts // save master for compare product
		},
		updateLiveProducts(liveProducts: Array<IProductListRes>) {
			this.liveProducts = liveProducts
		},
		removeLiveProductById(liveProductsID: string) {
			this.liveProducts = this.liveProducts.filter((e: IProductListRes) => {
				return e._id !== liveProductsID
			})
		},
		removeLiveSubProductById(liveSubProductsID: string) {
			this.liveProducts = this.liveProducts.map((e: any) => {
				return {
					...e,
					subProducts: e.subProducts.filter((y: any) => {
						return y._id !== liveSubProductsID
					})
				}
			})
		},
		updateSoldProductById(product: any) {
			//
			const findUpdateProduct = this.liveProducts.find((each: any) => each._id === product._id)
			if (findUpdateProduct) {
				(findUpdateProduct as any).sold = product.sold
			}
		},
		// actions
		async getAllPageLiveList(payload: FilterLivePayload) {
			try {
				const { data: liveList } = await getLiveListInPage(payload)
				this.updatePageLiveList(liveList)
			} catch (error) {
				return Promise.reject(error)
			}
		}
	}
})