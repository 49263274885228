
import { LIVE_STATUS } from '@/api/live/live.type'
import { computed, defineComponent, PropType } from 'vue'

export default defineComponent({
	name: 'LiveStatusBadge',
	props: {
		status: {
			type: String as PropType<LIVE_STATUS>,
			default: ''
		}
	},
	setup(props) {
		//
		const color = () => {
			//
			if (props.status === LIVE_STATUS.DRAFT) {
				return 'draft'
			}
			if (props.status === LIVE_STATUS.LIVE) {
				return 'live'
			} 
			if (props.status === LIVE_STATUS.COMPLETED) {
				return 'completed'
			} 
		}

		const text = computed(() => {
			if (props.status === LIVE_STATUS.DRAFT) return 'ร่าง'
			if (props.status === LIVE_STATUS.LIVE) return 'ถ่ายทอดสด'
			if (props.status === LIVE_STATUS.COMPLETED) return 'เสร็จสิ้น'
			return ''
		})

		return {
			color,
			text
		}
	}
})
